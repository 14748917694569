@import "project-variables";

// bower:scss
@import "../../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../../bower_components/nodes-ui/scss/nodes.scss";
// endbower


@-moz-document url-prefix() {
  .content-container {
    margin-bottom:70px !important;
  }
}