@include table-coloring('info');
@include table-coloring('success');
@include table-coloring('warning');
@include table-coloring('danger');

.table {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> tr,
			> td {
				&.shaded {
					background-color: $gray-light;
				}
			}
		}
		> tr.shaded {
			> tr,
			> td {
				background-color: $gray-light;
			}
		}
	}
}

.table-striped {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			&:nth-of-type(odd) {
				> tr,
				> td {
					&.shaded {
						background-image: linear-gradient(to right, $gray-050, $gray-050);
						background-color: transparent;
					}
				}
			}
			&:nth-of-type(even) {
				> tr,
				> td {
					&.shaded {
						background-image: linear-gradient(to right, $gray-025, $gray-025);
						background-color: transparent;
					}
				}
			}
		}
		> tr:nth-of-type(odd).shaded {
			> tr,
			> td {
				background-color: $gray;
			}
		}
		> tr:nth-of-type(even).shaded {
			> tr,
			> td {
				background-color: $gray-light;
			}
		}
	}
}

.table-striped.table-hover {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			&:nth-of-type(odd):hover {
				> tr,
				> td {
					&.shaded {
						background-color: $gray-dark;
						color: $gray-darker;
					}
				}
			}
			&:nth-of-type(even):hover {
				> tr,
				> td {
					&.shaded {
						background-color: $gray;
					}
				}
			}
		}
		> tr:nth-of-type(odd).shaded:hover {
			> tr,
			> td {
				background-color: $gray-dark;
				color: $gray-darker;
			}
		}
		> tr:nth-of-type(even).shaded:hover {
			> tr,
			> td {
				background-color: $gray;
			}
		}
	}
}