$form-label-distance-top-base: ($input-height-base - $padding-base-vertical) * 0.5;

.form-group.floating-label {
	border-bottom: 1px solid $gray;
	margin-bottom: 0;
	position: relative;
}
.form-group.floating-label.floating-label-no-border {
	border-bottom: none;
}

.form-group.floating-label.floating-label--focus {
	input,
	textarea {
		background-color: transparent;
	}
	label {
		color: $secondary-color-light;
	}
}
.form-group.floating-label.floating-label--value label {
	transform: translateY(0);
	opacity: 1;
}

.form-group.floating-label input,
.form-group.floating-label textarea {
	border: none;
	border-radius: 0;
	position: relative;
	z-index: 1;

	transition: background-color $animation-timing-short $animation-easing-relaxed;
}

.form-group.floating-label label {
	display: block;
	position: relative;
	margin: 0;
	padding-left: $padding-base-horizontal;
	padding-right: $padding-base-horizontal;
	opacity: 0;
	z-index: 0;
	font-size: $font-size-small;
	font-weight: normal;
	vertical-align: middle;
	color: $gray;
	transform: translateY($form-label-distance-top-base);

	transition: transform $animation-timing-short $animation-easing-relaxed,
				opacity $animation-timing-short $animation-easing-relaxed;
}

.form-group.floating-label.floating-label-sm label {
	padding-left: $padding-small-horizontal;
	padding-right: $padding-small-horizontal;
}

.form-group.floating-label.floating-label-lg label {
	padding-left: $padding-large-horizontal;
	padding-right: $padding-large-horizontal;
}