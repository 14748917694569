/*******************************
            Flex Layout
  *******************************/
.layout.horizontal,
.layout.horizontal-reverse,
.layout.vertical,
.layout.vertical-reverse {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.layout.inline {
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}
.layout.horizontal {
	-ms-flex-direction: row;
	-webkit-flex-direction: row;
	flex-direction: row;
}
.layout.horizontal-reverse {
	-ms-flex-direction: row-reverse;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
.layout.vertical {
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	flex-direction: column;
}
.layout.vertical-reverse {
	-ms-flex-direction: column-reverse;
	-webkit-flex-direction: column-reverse;
	flex-direction: column-reverse;
}
.layout.wrap {
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.layout.wrap-reverse {
	-ms-flex-wrap: wrap-reverse;
	-webkit-flex-wrap: wrap-reverse;
	flex-wrap: wrap-reverse;
}
.flex-auto {
	-ms-flex: 1 1 auto;
	-webkit-flex: 1 1 auto;
	flex: 1 1 auto;
}
.flex-none {
	-ms-flex: none;
	-webkit-flex: none;
	flex: none;
}
.flex,
.flex-1 {
	-ms-flex: 1;
	-webkit-flex: 1;
	flex: 1;
}
.flex-2 {
	-ms-flex: 2;
	-webkit-flex: 2;
	flex: 2;
}
.flex-3 {
	-ms-flex: 3;
	-webkit-flex: 3;
	flex: 3;
}
.flex-4 {
	-ms-flex: 4;
	-webkit-flex: 4;
	flex: 4;
}
.flex-5 {
	-ms-flex: 5;
	-webkit-flex: 5;
	flex: 5;
}
.flex-6 {
	-ms-flex: 6;
	-webkit-flex: 6;
	flex: 6;
}
.flex-7 {
	-ms-flex: 7;
	-webkit-flex: 7;
	flex: 7;
}
.flex-8 {
	-ms-flex: 8;
	-webkit-flex: 8;
	flex: 8;
}
.flex-9 {
	-ms-flex: 9;
	-webkit-flex: 9;
	flex: 9;
}
.flex-10 {
	-ms-flex: 10;
	-webkit-flex: 10;
	flex: 10;
}
.flex-11 {
	-ms-flex: 11;
	-webkit-flex: 11;
	flex: 11;
}
.flex-12 {
	-ms-flex: 12;
	-webkit-flex: 12;
	flex: 12;
}
/* alignment in cross axis */
.layout.start {
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	align-items: flex-start;
}
.layout.center,
.layout.center-center {
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
.layout.end {
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	align-items: flex-end;
}
/* alignment in main axis */
.layout.start-justified {
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}
.layout.center-justified,
.layout.center-center {
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.layout.end-justified {
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}
.layout.around-justified {
	-ms-flex-pack: around;
	-webkit-justify-content: space-around;
	justify-content: space-around;
}
.layout.justified {
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
/* self alignment */
.self-start {
	-ms-align-self: flex-start;
	-webkit-align-self: flex-start;
	align-self: flex-start;
}
.self-center {
	-ms-align-self: center;
	-webkit-align-self: center;
	align-self: center;
}
.self-end {
	-ms-align-self: flex-end;
	-webkit-align-self: flex-end;
	align-self: flex-end;
}
.self-stretch {
	-ms-align-self: stretch;
	-webkit-align-self: stretch;
	align-self: stretch;
}
/*******************************
			Other Layout
  *******************************/
.block {
	display: block;
}
/* IE 10 support for HTML5 hidden attr */
[hidden] {
	display: none !important;
}
.invisible {
	visibility: hidden !important;
}
.relative {
	position: relative;
}
.fit {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
body.fullbleed {
	margin: 0;
	height: 100vh;
}
.scroll {
	-webkit-overflow-scrolling: touch;
	overflow: auto;
}
.scroll-y {
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	overflow-y: auto;
}
.scroll-x {
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	overflow-y: hidden;
}
/* fixed position */
.fixed-bottom,
.fixed-left,
.fixed-right,
.fixed-top {
	position: fixed;
}
.fixed-top {
	top: 0;
	left: 0;
	right: 0;
}
.fixed-right {
	top: 0;
	right: 0;
	bottom: 0;
}
.fixed-bottom {
	right: 0;
	bottom: 0;
	left: 0;
}
.fixed-left {
	top: 0;
	bottom: 0;
	left: 0;
}

// Fix Bootstraps annoying negative margin!
.layout .row {
	margin: 0;
}

.center-vertical {
	display: flex;
	align-items: center;
}
.center-vertical-sm {
	@media (min-width: $screen-sm) {
		display: flex;
		align-items: center;
	}
}
.center-vertical-md {
	@media (min-width: $screen-md) {
		display: flex;
		align-items: center;
	}
}
.center-vertical-lg {
	@media (min-width: $screen-lg) {
		display: flex;
		align-items: center;
	}
}
.center-vertical-xl {
	@media (min-width: $screen-xl) {
		display: flex;
		align-items: center;
	}
}
.center-vertical-xxl {
	@media (min-width: $screen-xxl) {
		display: flex;
		align-items: center;
	}
}