th.sorting,
th.sorting_asc,
th.sorting_desc,
td.sorting,
td.sorting_asc,
td.sorting_desc {
	position: relative;
	padding-right: $table-cell-padding * 2;
	@media (min-width: $screen-xl-min) {
		padding-right: $table-cell-padding * 4;
	}
}

th.sorting:after,
td.sorting:after{
	opacity: 0;
}
th.sorting:hover:after,
td.sorting:hover:after {
	opacity: 1;
}
th.sorting_asc:after,
th.sorting_desc:after,
td.sorting_asc:after,
td.sorting_desc:after {
	opacity: 1;
}

th.sorting:after,
th.sorting_asc:after,
th.sorting_desc:after,
td.sorting:after,
td.sorting_asc:after,
td.sorting_desc:after {
	position: absolute;
	top: 50%;
	margin-top: -7px;
	right: 10px;
	display: block;
	font-family: FontAwesome;
	font-size: 14px;
	line-height: 1;
	background-color: $white;
	padding: 0 3px;
}
th.sorting:after,
td.sorting:after {
	content: "\f0dc";
	color: #ddd;
}
th.sorting_asc:after,
td.sorting_asc:after {
	content: "\f0de";
}
th.sorting_desc:after,
td.sorting_desc:after {
	content: "\f0dd";
}