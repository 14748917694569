.table {
	margin-bottom: 0;
	> thead > tr {
		th,
		td {
			padding: $padding-base-vertical $padding-large-vertical;
			font-weight: $font-weight-semi-bold;
			border-bottom-width: 0;
		}
	}
	> tbody > tr {
		> th,
		> td {
			@include font-color($secondary-color-light);
		}
	}
}

.table-bordered {
	> thead > tr {
		th,
		td {
			border-bottom-width: 0;
		}
	}
}

.table-striped.table-hover {
	> tbody > tr {
		&:nth-of-type(odd):hover {
			background-color: $gray-light;
		}
		&:nth-of-type(even):hover {
			background-color: $gray-lighter;
		}
	}
}

.table-condensed {
	thead > tr {
		th,
		td {
			@include whitespace($side: horizontal, $size: sm);
		}
	}
}

.table-expanded {
	thead > tr {
		th,
		td {
			font-size: $font-size-large;
		}
	}
	thead > tr {
		th,
		td {
			@include whitespace($side: horizontal, $size: lg);
			@include whitespace($side: vertical);
		}
	}
	tbody > tr {
		th,
		td {
			@include whitespace($size: lg);
		}
	}
}