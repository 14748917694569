//
//  Primary Colors
//
.background-primary {
	background-color: $primary-color;
}
.background-primary-025 {
	background-color: $primary-color-025;
}
.background-primary-050 {
	background-color: $primary-color-050;
}
.background-primary-darker {
	background-color: $primary-color-darker;
}
.background-primary-darker-025 {
	background-color: $primary-color-darker-025;
}
.background-primary-darker-050 {
	background-color: $primary-color-darker-050;
}
.background-primary-dark {
	background-color: $primary-color-dark;
}
.background-primary-dark-025 {
	background-color: $primary-color-dark-025;
}
.background-primary-dark-050 {
	background-color: $primary-color-dark-050;
}
.background-primary-light {
	background-color: $primary-color-light;
}
.background-primary-light-025 {
	background-color: $primary-color-light-025;
}
.background-primary-light-050 {
	background-color: $primary-color-light-050;
}
.background-primary-lighter {
	background-color: $primary-color-lighter;
}
.background-primary-lighter-025 {
	background-color: $primary-color-lighter-025;
}
.background-primary-lighter-050 {
	background-color: $primary-color-lighter-050;
}
.text-primary {
	color: $primary-color;
}
.text-primary-025 {
	color: $primary-color-025;
}
.text-primary-050 {
	color: $primary-color-050;
}
.text-primary-darker {
	color: $primary-color-darker;
}
.text-primary-darker-025 {
	color: $primary-color-darker-025;
}
.text-primary-darker-050 {
	color: $primary-color-darker-050;
}
.text-primary-dark {
	color: $primary-color-dark;
}
.text-primary-dark-025 {
	color: $primary-color-dark-025;
}
.text-primary-dark-050 {
	color: $primary-color-dark-050;
}
.text-primary-light {
	color: $primary-color-light;
}
.text-primary-light-025 {
	color: $primary-color-light-025;
}
.text-primary-light-050 {
	color: $primary-color-light-050;
}
.text-primary-lighter {
	color: $primary-color-lighter;
}
.text-primary-lighter-025 {
	color: $primary-color-lighter-025;
}
.text-primary-lighter-050 {
	color: $primary-color-lighter-050;
}

//
// Secondary Colors
//
.background-secondary {
	background-color: $secondary-color;
}
.background-secondary-025 {
	background-color: $secondary-color-025;
}
.background-secondary-050 {
	background-color: $secondary-color-050;
}
.background-secondary-darker {
	background-color: $secondary-color-darker;
}
.background-secondary-darker-025 {
	background-color: $secondary-color-darker-025;
}
.background-secondary-darker-050 {
	background-color: $secondary-color-darker-050;
}
.background-secondary-dark {
	background-color: $secondary-color-dark;
}
.background-secondary-dark-025 {
	background-color: $secondary-color-dark-025;
}
.background-secondary-dark-050 {
	background-color: $secondary-color-dark-050;
}
.background-secondary-light {
	background-color: $secondary-color-light;
}
.background-secondary-light-025 {
	background-color: $secondary-color-light-025;
}
.background-secondary-light-050 {
	background-color: $secondary-color-light-050;
}
.background-secondary-lighter {
	background-color: $secondary-color-lighter;
}
.background-secondary-lighter-025 {
	background-color: $secondary-color-lighter-025;
}
.background-secondary-lighter-050 {
	background-color: $secondary-color-lighter-050;
}
.text-secondary {
	color: $secondary-color;
}
.text-secondary-025 {
	color: $secondary-color-025;
}
.text-secondary-050 {
	color: $secondary-color-050;
}
.text-secondary-darker {
	color: $secondary-color-darker;
}
.text-secondary-darker-025 {
	color: $secondary-color-darker-025;
}
.text-secondary-darker-050 {
	color: $secondary-color-darker-050;
}
.text-secondary-dark {
	color: $secondary-color-dark;
}
.text-secondary-dark-025 {
	color: $secondary-color-dark-025;
}
.text-secondary-dark-050 {
	color: $secondary-color-dark-050;
}
.text-secondary-light {
	color: $secondary-color-light;
}
.text-secondary-light-025 {
	color: $secondary-color-light-025;
}
.text-secondary-light-050 {
	color: $secondary-color-light-050;
}
.text-secondary-lighter {
	color: $secondary-color-lighter;
}
.text-secondary-lighter-025 {
	color: $secondary-color-lighter-025;
}
.text-secondary-lighter-050 {
	color: $secondary-color-lighter-050;
}

//
// Grays
//
.background-gray {
	background-color: $gray;
}
.background-gray-025 {
	background-color: $gray-025;
}
.background-gray-050 {
	background-color: $gray-050;
}
.background-gray-darker {
	background-color: $gray-darker;
}
.background-gray-darker-025 {
	background-color: $gray-darker-025;
}
.background-gray-darker-050 {
	background-color: $gray-darker-050;
}
.background-gray-dark {
	background-color: $gray-dark;
}
.background-gray-dark-025 {
	background-color: $gray-dark-025;
}
.background-gray-dark-050 {
	background-color: $gray-dark-050;
}
.background-gray-light {
	background-color: $gray-light;
}
.background-gray-light-025 {
	background-color: $gray-light-025;
}
.background-gray-light-050 {
	background-color: $gray-light-050;
}
.background-gray-lighter {
	background-color: $gray-lighter;
}
.background-gray-lighter-025 {
	background-color: $gray-lighter-025;
}
.background-gray-lighter-050 {
	background-color: $gray-lighter-050;
}
.text-gray {
	color: $gray;
}
.text-gray-025 {
	color: $gray-025;
}
.text-gray-050 {
	color: $gray-050;
}
.text-gray-darker {
	color: $gray-darker;
}
.text-gray-darker-025 {
	color: $gray-darker-025;
}
.text-gray-darker-050 {
	color: $gray-darker-050;
}
.text-gray-dark {
	color: $gray-dark;
}
.text-gray-dark-025 {
	color: $gray-dark-025;
}
.text-gray-dark-050 {
	color: $gray-dark-050;
}
.text-gray-light {
	color: $gray-light;
}
.text-gray-light-025 {
	color: $gray-light-025;
}
.text-gray-light-050 {
	color: $gray-light-050;
}
.text-gray-lighter {
	color: $gray-lighter;
}
.text-gray-lighter-025 {
	color: $gray-lighter-025;
}
.text-gray-lighter-050 {
	color: $gray-lighter-050;
}

//
// Contextual Colors
//

// Info
.background-info {
	background-color: $info-color;
}
.background-info-025 {
	background-color: $info-color-025;
}
.background-info-050 {
	background-color: $info-color-050;
}
.background-info-darker {
	background-color: $info-color-darker;
}
.background-info-darker-025 {
	background-color: $info-color-darker-025;
}
.background-info-darker-050 {
	background-color: $info-color-darker-050;
}
.background-info-dark {
	background-color: $info-color-dark;
}
.background-info-dark-025 {
	background-color: $info-color-dark-025;
}
.background-info-dark-050 {
	background-color: $info-color-dark-050;
}
.background-info-light {
	background-color: $info-color-light;
}
.background-info-light-025 {
	background-color: $info-color-light-025;
}
.background-info-light-050 {
	background-color: $info-color-light-050;
}
.background-info-lighter {
	background-color: $info-color-lighter;
}
.background-info-lighter-025 {
	background-color: $info-color-lighter-025;
}
.background-info-lighter-050 {
	background-color: $info-color-lighter-050;
}
.text-info {
	color: $info-color;
}
.text-info-025 {
	color: $info-color-025;
}
.text-info-050 {
	color: $info-color-050;
}
.text-info-darker {
	color: $info-color-darker;
}
.text-info-darker-025 {
	color: $info-color-darker-025;
}
.text-info-darker-050 {
	color: $info-color-darker-050;
}
.text-info-dark {
	color: $info-color-dark;
}
.text-info-dark-025 {
	color: $info-color-dark-025;
}
.text-info-dark-050 {
	color: $info-color-dark-050;
}
.text-info-light {
	color: $info-color-light;
}
.text-info-light-025 {
	color: $info-color-light-025;
}
.text-info-light-050 {
	color: $info-color-light-050;
}
.text-info-lighter {
	color: $info-color-lighter;
}
.text-info-lighter-025 {
	color: $info-color-lighter-025;
}
.text-info-lighter-050 {
	color: $info-color-lighter-050;
}

// Success
.background-success {
	background-color: $success-color;
}
.background-success-025 {
	background-color: $success-color-025;
}
.background-success-050 {
	background-color: $success-color-050;
}
.background-success-darker {
	background-color: $success-color-darker;
}
.background-success-darker-025 {
	background-color: $success-color-darker-025;
}
.background-success-darker-050 {
	background-color: $success-color-darker-050;
}
.background-success-dark {
	background-color: $success-color-dark;
}
.background-success-dark-025 {
	background-color: $success-color-dark-025;
}
.background-success-dark-050 {
	background-color: $success-color-dark-050;
}
.background-success-light {
	background-color: $success-color-light;
}
.background-success-light-025 {
	background-color: $success-color-light-025;
}
.background-success-light-050 {
	background-color: $success-color-light-050;
}
.background-success-lighter {
	background-color: $success-color-lighter;
}
.background-success-lighter-025 {
	background-color: $success-color-lighter-025;
}
.background-success-lighter-050 {
	background-color: $success-color-lighter-050;
}
.text-success {
	color: $success-color;
}
.text-success-025 {
	color: $success-color-025;
}
.text-success-050 {
	color: $success-color-050;
}
.text-success-darker {
	color: $success-color-darker;
}
.text-success-darker-025 {
	color: $success-color-darker-025;
}
.text-success-darker-050 {
	color: $success-color-darker-050;
}
.text-success-dark {
	color: $success-color-dark;
}
.text-success-dark-025 {
	color: $success-color-dark-025;
}
.text-success-dark-050 {
	color: $success-color-dark-050;
}
.text-success-light {
	color: $success-color-light;
}
.text-success-light-025 {
	color: $success-color-light-025;
}
.text-success-light-050 {
	color: $success-color-light-050;
}
.text-success-lighter {
	color: $success-color-lighter;
}
.text-success-lighter-025 {
	color: $success-color-lighter-025;
}
.text-success-lighter-050 {
	color: $success-color-lighter-050;
}

// Warning
.background-warning {
	background-color: $warning-color;
}
.background-warning-025 {
	background-color: $warning-color-025;
}
.background-warning-050 {
	background-color: $warning-color-050;
}
.background-warning-darker {
	background-color: $warning-color-darker;
}
.background-warning-darker-025 {
	background-color: $warning-color-darker-025;
}
.background-warning-darker-050 {
	background-color: $warning-color-darker-050;
}
.background-warning-dark {
	background-color: $warning-color-dark;
}
.background-warning-dark-025 {
	background-color: $warning-color-dark-025;
}
.background-warning-dark-050 {
	background-color: $warning-color-dark-050;
}
.background-warning-light {
	background-color: $warning-color-light;
}
.background-warning-light-025 {
	background-color: $warning-color-light-025;
}
.background-warning-light-050 {
	background-color: $warning-color-light-050;
}
.background-warning-lighter {
	background-color: $warning-color-lighter;
}
.background-warning-lighter-025 {
	background-color: $warning-color-lighter-025;
}
.background-warning-lighter-050 {
	background-color: $warning-color-lighter-050;
}
.text-warning {
	color: $warning-color;
}
.text-warning-025 {
	color: $warning-color-025;
}
.text-warning-050 {
	color: $warning-color-050;
}
.text-warning-darker {
	color: $warning-color-darker;
}
.text-warning-darker-025 {
	color: $warning-color-darker-025;
}
.text-warning-darker-050 {
	color: $warning-color-darker-050;
}
.text-warning-dark {
	color: $warning-color-dark;
}
.text-warning-dark-025 {
	color: $warning-color-dark-025;
}
.text-warning-dark-050 {
	color: $warning-color-dark-050;
}
.text-warning-light {
	color: $warning-color-light;
}
.text-warning-light-025 {
	color: $warning-color-light-025;
}
.text-warning-light-050 {
	color: $warning-color-light-050;
}
.text-warning-lighter {
	color: $warning-color-lighter;
}
.text-warning-lighter-025 {
	color: $warning-color-lighter-025;
}
.text-warning-lighter-050 {
	color: $warning-color-lighter-050;
}

// Danger
.background-danger {
	background-color: $danger-color;
}
.background-danger-025 {
	background-color: $danger-color-025;
}
.background-danger-050 {
	background-color: $danger-color-050;
}
.background-danger-darker {
	background-color: $danger-color-darker;
}
.background-danger-darker-025 {
	background-color: $danger-color-darker-025;
}
.background-danger-darker-050 {
	background-color: $danger-color-darker-050;
}
.background-danger-dark {
	background-color: $danger-color-dark;
}
.background-danger-dark-025 {
	background-color: $danger-color-dark-025;
}
.background-danger-dark-050 {
	background-color: $danger-color-dark-050;
}
.background-danger-light {
	background-color: $danger-color-light;
}
.background-danger-light-025 {
	background-color: $danger-color-light-025;
}
.background-danger-light-050 {
	background-color: $danger-color-light-050;
}
.background-danger-lighter {
	background-color: $danger-color-lighter;
}
.background-danger-lighter-025 {
	background-color: $danger-color-lighter-025;
}
.background-danger-lighter-050 {
	background-color: $danger-color-lighter-050;
}
.text-danger {
	color: $danger-color;
}
.text-danger-025 {
	color: $danger-color-025;
}
.text-danger-050 {
	color: $danger-color-050;
}
.text-danger-darker {
	color: $danger-color-darker;
}
.text-danger-darker-025 {
	color: $danger-color-darker-025;
}
.text-danger-darker-050 {
	color: $danger-color-darker-050;
}
.text-danger-dark {
	color: $danger-color-dark;
}
.text-danger-dark-025 {
	color: $danger-color-dark-025;
}
.text-danger-dark-050 {
	color: $danger-color-dark-050;
}
.text-danger-light {
	color: $danger-color-light;
}
.text-danger-light-025 {
	color: $danger-color-light-025;
}
.text-danger-light-050 {
	color: $danger-color-light-050;
}
.text-danger-lighter {
	color: $danger-color-lighter;
}
.text-danger-lighter-025 {
	color: $danger-color-lighter-025;
}
.text-danger-lighter-050 {
	color: $danger-color-lighter-050;
}

//
// Not so much colors...
//
.background-white {
	background-color: $white;
}
.text-white {
	color: $white;
}
.background-black {
	background-color: $black;
}
.text-black {
	color: $black;
}

//
// Text Shading
//
.text-shade-light-primary {
	color: $white;
	opacity: $text-shade-light-primary-opacity;
}
.text-shade-light-secondary {
	color: $white;
	opacity: $text-shade-light-secondary-opacity;
}
.text-shade-light-hint {
	color: $white;
	opacity: $text-shade-light-hint-opacity;
}

.text-shade-dark-primary {
	color: $black;
	opacity: $text-shade-dark-primary-opacity;
}
.text-shade-dark-secondary {
	color: $black;
	opacity: $text-shade-dark-secondary-opacity;
}
.text-shade-dark-hint {
	color: $black;
	opacity: $text-shade-dark-hint-opacity;
}

//
// Borders
//
[class*="use-border"]:not(.table) {
	border: 1px solid;
}
[class*="use-border-vertical"]:not(.table) {
	border-top: 1px solid;
	border-bottom: 1px solid;
}
[class*="use-border-horizontal"]:not(.table) {
	border-right: 1px solid;
	border-left: 1px solid;
}
[class*="use-border-top"]:not(.table) {
	border-top: 1px solid;
}
[class*="use-border-right"]:not(.table) {
	border-right: 1px solid;
}
[class*="use-border-bottom"]:not(.table) {
	border-bottom: 1px solid;
}
[class*="use-border-left"]:not(.table) {
	border-left: 1px solid;
}

.border-primary-darker {
	border-color: $primary-color-darker;
}
.border-primary-dark {
	border-color: $primary-color-dark;
}
.border-primary {
	border-color: $primary-color;
}
.border-primary-light {
	border-color: $primary-color-light;
}
.border-primary-lighter {
	border-color: $primary-color-lighter;
}

.border-secondary-darker {
	border-color: $secondary-color-darker;
}
.border-secondary-dark {
	border-color: $secondary-color-dark;
}
.border-secondary {
	border-color: $secondary-color;
}
.border-secondary-light {
	border-color: $secondary-color-light;
}
.border-secondary-lighter {
	border-color: $secondary-color-lighter;
}

.border-info-darker {
	border-color: $info-color-darker;
}
.border-info-dark {
	border-color: $info-color-dark;
}
.border-info {
	border-color: $info-color;
}
.border-info-light {
	border-color: $info-color-light;
}
.border-info-lighter {
	border-color: $info-color-lighter;
}

.border-success-darker {
	border-color: $success-color-darker;
}
.border-success-dark {
	border-color: $success-color-dark;
}
.border-success {
	border-color: $success-color;
}
.border-success-light {
	border-color: $success-color-light;
}
.border-success-lighter {
	border-color: $success-color-lighter;
}

.border-warning-darker {
	border-color: $warning-color-darker;
}
.border-warning-dark {
	border-color: $warning-color-dark;
}
.border-warning {
	border-color: $warning-color;
}
.border-warning-light {
	border-color: $warning-color-light;
}
.border-warning-lighter {
	border-color: $warning-color-lighter;
}

.border-danger-darker {
	border-color: $danger-color-darker;
}
.border-danger-dark {
	border-color: $danger-color-dark;
}
.border-danger {
	border-color: $danger-color;
}
.border-danger-light {
	border-color: $danger-color-light;
}
.border-danger-lighter {
	border-color: $danger-color-lighter;
}