.dropdown {

}

.dropdown-menu {
	margin: 0;
	padding: 0;
	overflow: hidden;
	border-radius: 0;
	border: none;

	&.align-right {
		left: auto;
		right: 0;
	}

	&.align-left {
		left: 0;
		right: auto;
	}

	&.dropdown-sm {
		min-width: 100px;
		max-width: 100px;
	}
	&.dropdown-lg {
		min-width: 300px;
		max-width: 300px;
	}
}

.dropdown-menu > ul > li > a {
	padding: 0;
	@include whitespace($side: vertical, $size: xs);
	@include whitespace($side: horizontal, $size: sm);

	display: block;
	clear: both;
	font-weight: normal;
	line-height: $line-height-base;
	color: $dropdown-link-color;
	white-space: nowrap; // prevent links from randomly breaking onto new lines
}

.dropdown-header {
	padding: 0;
	@include whitespace($side: vertical, $size: xs);
	@include whitespace($side: horizontal, $size: sm);
	@include font-color($secondary-color);
}

.dropdown-content {
	@include whitespace($size: xs);
}

.dropdown-inverse {
	@include background-color($secondary-color-dark);
	box-shadow: none;

	.dropdown-content__title {
		@include font-color($secondary-color-light);
		border-top: 1px solid $secondary-color;
		&:first-of-type {
			border-top: none;
		}
	}

	> li {
		> a:hover, > a:focus, > a:active,
		> a.hover, > a.focus, > a.active {
			@include background-color($secondary-color-darker);
		}
	}

	.divider {
		@include background-color($secondary-color);
	}

	.dropdown-header {
		@include font-color(darken($secondary-color-light, 2.5%));
	}

}


.dropdown-content__title {
	@include whitespace($side: vertical, $size: sm);
	margin-bottom: 0;
	text-align: center;
}