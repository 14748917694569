//
// Paddings
//

// All around
.padding {
	padding: $whitespace-root * $whitespace-base-modifier;
}
.padding-xs {
	padding: $whitespace-root;
}
.padding-sm {
	padding: $whitespace-root * $whitespace-small-modifier;
}
.padding-lg {
	padding: $whitespace-root * $whitespace-large-modifier;
}
.padding-xl {
	padding: $whitespace-root * $whitespace-xlarge-modifier;
}

// Horizontal only
.padding-horizontal {
	padding-left: $whitespace-root * $whitespace-base-modifier;
	padding-right: $whitespace-root * $whitespace-base-modifier;
}
.padding-horizontal-xs {
	padding-left: $whitespace-root;
	padding-right: $whitespace-root;
}
.padding-horizontal-sm {
	padding-left: $whitespace-root * $whitespace-small-modifier;
	padding-right: $whitespace-root * $whitespace-small-modifier;
}
.padding-horizontal-lg {
	padding-left: $whitespace-root * $whitespace-large-modifier;
	padding-right: $whitespace-root * $whitespace-large-modifier;
}
.padding-horizontal-xl {
	padding-left: $whitespace-root * $whitespace-xlarge-modifier;
	padding-right: $whitespace-root * $whitespace-xlarge-modifier;
}

// Vertical only
.padding-vertical {
	padding-top: $whitespace-root * $whitespace-base-modifier;
	padding-bottom: $whitespace-root * $whitespace-base-modifier;
}
.padding-vertical-xs {
	padding-top: $whitespace-root;
	padding-bottom: $whitespace-root;
}
.padding-vertical-sm {
	padding-top: $whitespace-root * $whitespace-small-modifier;
	padding-bottom: $whitespace-root * $whitespace-small-modifier;
}
.padding-vertical-lg {
	padding-top: $whitespace-root * $whitespace-large-modifier;
	padding-bottom: $whitespace-root * $whitespace-large-modifier;
}
.padding-vertical-xl {
	padding-top: $whitespace-root * $whitespace-xlarge-modifier;
	padding-bottom: $whitespace-root * $whitespace-xlarge-modifier;
}

// Top only
.padding-top {
	padding-top: $whitespace-root * $whitespace-base-modifier;
}
.padding-top-xs {
	padding-top: $whitespace-root;
}
.padding-top-sm {
	padding-top: $whitespace-root * $whitespace-small-modifier;
}
.padding-top-lg {
	padding-top: $whitespace-root * $whitespace-large-modifier;
}
.padding-top-xl {
	padding-top: $whitespace-root * $whitespace-xlarge-modifier;
}

// Right only
.padding-right {
	padding-right: $whitespace-root * $whitespace-base-modifier;
}
.padding-right-xs {
	padding-right: $whitespace-root;
}
.padding-right-sm {
	padding-right: $whitespace-root * $whitespace-small-modifier;
}
.padding-right-lg {
	padding-right: $whitespace-root * $whitespace-large-modifier;
}
.padding-right-xl {
	padding-right: $whitespace-root * $whitespace-xlarge-modifier;
}

// Bottom only
.padding-bottom {
	padding-bottom: $whitespace-root * $whitespace-base-modifier;
}
.padding-bottom-xs {
	padding-bottom: $whitespace-root;
}
.padding-bottom-sm {
	padding-bottom: $whitespace-root * $whitespace-small-modifier;
}
.padding-bottom-lg {
	padding-bottom: $whitespace-root * $whitespace-large-modifier;
}
.padding-bottom-xl {
	padding-bottom: $whitespace-root * $whitespace-xlarge-modifier;
}

// Left only
.padding-left {
	padding-left: $whitespace-root * $whitespace-base-modifier;
}
.padding-left-xs {
	padding-left: $whitespace-root;
}
.padding-left-sm {
	padding-left: $whitespace-root * $whitespace-small-modifier;
}
.padding-left-lg {
	padding-left: $whitespace-root * $whitespace-large-modifier;
}
.padding-left-xl {
	padding-left: $whitespace-root * $whitespace-xlarge-modifier;
}

//
// Margins
//

// All around
.margin {
	margin: $whitespace-root * $whitespace-base-modifier;
}
.margin-xs {
	margin: $whitespace-root;
}
.margin-sm {
	margin: $whitespace-root * $whitespace-small-modifier;
}
.margin-lg {
	margin: $whitespace-root * $whitespace-large-modifier;
}
.margin-xl {
	margin: $whitespace-root * $whitespace-xlarge-modifier;
}

// Horizontal only
.margin-horizontal {
	margin-left: $whitespace-root * $whitespace-base-modifier;
	margin-right: $whitespace-root * $whitespace-base-modifier;
}
.margin-horizontal-xs {
	margin-left: $whitespace-root;
	margin-right: $whitespace-root;
}
.margin-horizontal-sm {
	margin-left: $whitespace-root * $whitespace-small-modifier;
	margin-right: $whitespace-root * $whitespace-small-modifier;
}
.margin-horizontal-lg {
	margin-left: $whitespace-root * $whitespace-large-modifier;
	margin-right: $whitespace-root * $whitespace-large-modifier;
}
.margin-horizontal-xl {
	margin-left: $whitespace-root * $whitespace-xlarge-modifier;
	margin-right: $whitespace-root * $whitespace-xlarge-modifier;
}

// Vertical only
.margin-vertical {
	margin-top: $whitespace-root * $whitespace-base-modifier;
	margin-bottom: $whitespace-root * $whitespace-base-modifier;
}
.margin-vertical-xs {
	margin-top: $whitespace-root;
	margin-bottom: $whitespace-root;
}
.margin-vertical-sm {
	margin-top: $whitespace-root * $whitespace-small-modifier;
	margin-bottom: $whitespace-root * $whitespace-small-modifier;
}
.margin-vertical-lg {
	margin-top: $whitespace-root * $whitespace-large-modifier;
	margin-bottom: $whitespace-root * $whitespace-large-modifier;
}
.margin-vertical-xl {
	margin-top: $whitespace-root * $whitespace-xlarge-modifier;
	margin-bottom: $whitespace-root * $whitespace-xlarge-modifier;
}

// Top only
.margin-top {
	margin-top: $whitespace-root * $whitespace-base-modifier;
}
.margin-top-xs {
	margin-top: $whitespace-root;
}
.margin-top-sm {
	margin-top: $whitespace-root * $whitespace-small-modifier;
}
.margin-top-lg {
	margin-top: $whitespace-root * $whitespace-large-modifier;
}
.margin-top-xl {
	margin-top: $whitespace-root * $whitespace-xlarge-modifier;
}
.xs-down-margin-top-lg {
	@media (max-width: $screen-sm) {
		margin-top: $whitespace-root * $whitespace-large-modifier;
	}
}
.md-margin-top-lg {
	@media (min-width: $screen-md) {
		margin-top: $whitespace-root * $whitespace-large-modifier;
	}
}


// Right only
.margin-right {
	margin-right: $whitespace-root * $whitespace-base-modifier;
}
.margin-right-xs {
	margin-right: $whitespace-root;
}
.margin-right-sm {
	margin-right: $whitespace-root * $whitespace-small-modifier;
}
.margin-right-lg {
	margin-right: $whitespace-root * $whitespace-large-modifier;
}
.margin-right-xl {
	margin-right: $whitespace-root * $whitespace-xlarge-modifier;
}

// Bottom only
.margin-bottom {
	margin-bottom: $whitespace-root * $whitespace-base-modifier;
}
.margin-bottom-xs {
	margin-bottom: $whitespace-root;
}
.margin-bottom-sm {
	margin-bottom: $whitespace-root * $whitespace-small-modifier;
}
.margin-bottom-lg {
	margin-bottom: $whitespace-root * $whitespace-large-modifier;
}
.margin-bottom-xl {
	margin-bottom: $whitespace-root * $whitespace-xlarge-modifier;
}

// Left only
.margin-left {
	margin-left: $whitespace-root * $whitespace-base-modifier;
}
.margin-left-xs {
	margin-left: $whitespace-root;
}
.margin-left-sm {
	margin-left: $whitespace-root * $whitespace-small-modifier;
}
.margin-left-lg {
	margin-left: $whitespace-root * $whitespace-large-modifier;
}
.margin-left-xl {
	margin-left: $whitespace-root * $whitespace-xlarge-modifier;
}

.margin-left-auto {
	margin-left: $whitespace-root * $whitespace-base-modifier;
}
.margin-left-xs-auto {
	@media (min-width: $screen-xs) {
		margin-left: auto;
	}
}
.margin-left-sm-auto {
	@media (min-width: $screen-sm) {
		margin-left: auto;
	}
}
.margin-left-md-auto {
	@media (min-width: $screen-md) {
		margin-left: auto;
	}
}
.margin-left-lg-auto {
	@media (min-width: $screen-lg) {
		margin-left: auto;
	}
}
.margin-left-xl-auto {
	@media (min-width: $screen-xl) {
		margin-left: auto;
	}
}
.margin-left-xxl-auto {
	@media (min-width: $screen-xxl) {
		margin-left: auto;
	}
}
.margin-left-down-xs-auto {
	@media (max-width: $screen-xs) {
		margin-left: auto;
	}
}
.margin-left-down-sm-auto {
	@media (max-width: $screen-sm) {
		margin-left: auto;
	}
}
.margin-left-down-md-auto {
	@media (max-width: $screen-md) {
		margin-left: auto;
	}
}
.margin-left-down-lg-auto {
	@media (max-width: $screen-lg) {
		margin-left: auto;
	}
}
.margin-left-down-xl-auto {
	@media (max-width: $screen-xl) {
		margin-left: auto;
	}
}
.margin-left-down-xxl-auto {
	@media (max-width: $screen-xxl) {
		margin-left: auto;
	}
}


//
// Resets
//
.no-padding {
	padding: 0 !important;
}
.no-padding-vertical {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.no-padding-horizontal {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.no-padding-top {
	padding-top: 0 !important;
}
.no-padding-right {
	padding-right: 0 !important;
}
.no-padding-bottom {
	padding-bottom: 0 !important;
}
.no-padding-left {
	padding-left: 0 !important;
}
.no-margin {
	margin: 0 !important;
}
.no-margin-vertical {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.no-margin-horizontal {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.no-margin-top {
	margin-top: 0 !important;
}
.no-margin-right {
	margin-right: 0 !important;
}
.no-margin-bottom {
	margin-bottom: 0 !important;
}
.no-margin-left {
	margin-left: 0 !important;
}
