.user__profile--inline {
	display: flex;
	align-items: center;
	.user__info-avatar,
	.user__info-name {
		float: left;
	}
	.user__info-avatar {
		@include whitespace($side: right, $type: margin, $size: sm);
	}
}