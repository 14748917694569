.user__info-button {
	.user__info-avatar {
		@include whitespace($side: right, $type: margin, $size: sm );
		float: left;
		width: 30px;
		height: 30px;
	}
	.user__info-name {
		display: block;
		line-height: 30px;
	}
}

.dropdown.dropdown-user {
	@include font-color($secondary-color-light);

	.dropdown-menu {
		max-width: $core-sidebar-width;
	}

	.dropdown-content {
		padding: 0;
	}

	.user,
	.company {
		@include layout($wrap: false);
		@include whitespace($side: horizontal);
		@include whitespace($side: bottom, $size: sm);
	}

	.user__profile,
	.company__profile {
		@include flex(1);
		min-width: 50px;
		max-width: 50px;
		border-radius: $border-radius-base;
		overflow: hidden;
	}
	.user__info,
	.company__info {
		@include layout($direction: vertical, $wrap: false);
		@include flex(2);
		@include whitespace($type: margin, $side: left);
		justify-content: center;
	}
	
	.user__info-name,
	.user__info-email,
	.company__info-name {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.company-list,
	.company-actions,
	.company-list-title {
		@include background-color($secondary-color-darker);
	}

	.user-actions {
		@include clearfix;
		@include whitespace($side: horizontal);
		.user__sign-out {
			padding-left: 0;
			float: left;
		}
		.user__settings {
			padding-right: 0;
			float: right;
		}
	}

	.company-list-title {
		@include whitespace($side: horizontal);
		margin-bottom: 0;
	}

	.company-list {
		@include whitespace($side: vertical, $size: xs);
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li a {
			@include whitespace($side: horizontal);
			@include whitespace($side: vertical, $size: xs);
			display: block;
			&:hover, &.hover,
			&:focus, &.focus,
			&:active, &.active {
				text-decoration: none;
			}
		}
	}

	.company-list__more {
		text-align: center;
		.fa {
			@include whitespace($type: margin, $side: right, $size: sm);
		}
	}

	.company__settings {
		@include whitespace($side: right);
		float: right;
	}

}