.indicator {
	@include indicator-size();
	@include indicator-color();

	&.indicator-xs {
		@include indicator-size($size: xs);
	}
	&.indicator-sm {
		@include indicator-size($size: sm);
	}
	&.indicator-lg {
		@include indicator-size($size: lg);
	}
	&.indicator-xl {
		@include indicator-size($size: xl);
	}
}

.indicator.indicator-baseline {
	vertical-align: baseline;
}
.indicator.indicator-middle {
	vertical-align: middle;
}

.indicator-secondary {
	@include indicator-color($secondary-color);
}
.indicator-info {
	@include indicator-color($info-color);
}
.indicator-success {
	@include indicator-color($success-color);
}
.indicator-warning {
	@include indicator-color($warning-color);
}
.indicator-danger {
	@include indicator-color($danger-color);
}