.app-tile__icon {
	@include whitespace($side: bottom, $type: margin);
	display: block;
	font-size: $font-size-mega;
	&.app-tile__icon--small {
		font-size: $font-size-h1;
	}
	&.app-tile__icon--large {
		font-size: $font-size-giga;
	}
}

img.app-tile__icon,
svg.app-tile__icon {
	width: $font-size-mega;
	height: $font-size-mega;
	&.app-tile__icon--small {
		width: $font-size-h1;
		height: $font-size-h1;
	}
	&.app-tile__icon--large {
		width: $font-size-giga;
		height: $font-size-giga;
	}
}