.list-group-heading {
	padding: 0;
	@include whitespace($side: horizontal);
	@include whitespace($side: vertical, $size: sm);
	@include layout($wrap: false);
	align-items: center;
	font-weight: 500;
	border-left: 6px solid transparent;
}
.list-group.list-group-inverse .list-group-heading {
	@include background-color($secondary-color-dark);
	@include font-color($secondary-color-light);
}