.auto-complete {
	position: relative;
}
.auto-complete__loading-indicator {
	pointer-events: none;
	z-index: 2;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	right: 5px;
	width: 20px;
	height: 20px;

	color: $input-color;
	transform: translateX(3px);
	opacity: 0;
	transition: all .1s;

	&.auto-complete__loading-indicator--active {
		opacity: 1;
		transform: translateX(0);
	}
}
.autocomplete-suggestions {

	overflow: auto;

	background-color: $dropdown-bg;
	border: 1px solid $dropdown-border;
	border-radius: $border-radius-base;
	@include box-shadow(0 6px 12px rgba(0,0,0,.175));
}
.autocomplete-suggestion {
	overflow: hidden;
	white-space: nowrap;

	display: block;
	@include whitespace($side: horizontal);
	@include whitespace($side: vertical, $size: sm);

	color: $dropdown-link-color;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $dropdown-link-hover-color;
		background-color: $dropdown-link-hover-bg;
		cursor: pointer;
	}
}
.autocomplete-selected {
	color: $dropdown-link-active-color;
	text-decoration: none;
	outline: 0;
	background-color: $dropdown-link-active-bg;
}
.autocomplete-suggestions strong {

}
.autocomplete-group {
	@include whitespace($side: horizontal, $size: sm);
	@include whitespace($side: vertical, $size: xs);
	background-color: $gray-lighter;
	color: $gray-dark;
}
.autocomplete-group strong {
	font-weight: normal;
}
.autocomplete-suggestions.autocomplete-suggestions--inverse {
	@include background-color($secondary-color-dark);

	.autocomplete-suggestion {
		@include font-color($secondary-color-light);

		&:hover,
		&:focus {
			@include background-color($secondary-color-darker);
		}
	}
	.autocomplete-selected {
		@include background-color($secondary-color-darker);
	}
	.autocomplete-group {
		@include background-color($secondary-color);
		@include font-color(darken($secondary-color-light, 2.5%));
	}
}