.logo {
	position: relative;
	width: $logo-size;
	height: $logo-size;
	display: inline-block;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.logo-gradient__0 {
		stop-color: $primary-color-dark;
	}
	.logo-gradient__100 {
		stop-color: $primary-color;
	}
}
.logo.logo-small {
	width: $logo-size-small;
	height: $logo-size-small;
}
.logo.logo-large {
	width: $logo-size-large;
	height: $logo-size-large;
}
.logo.logo-xlarge {
	width: $logo-size-xlarge;
	height: $logo-size-xlarge;
}

.logo.logo-flat {
	.logo-gradient__0 {
		stop-color: $primary-color;
	}
	.logo-gradient__100 {
		stop-color: $primary-color;
	}
}

.logo.logo-secondary {
	.logo-gradient__0 {
		stop-color: $secondary-color;
	}
	.logo-gradient__100 {
		stop-color: $secondary-color-dark;
	}
}

.logo.logo-secondary.logo-flat {
	.logo-gradient__0 {
		stop-color: $secondary-color;
	}
	.logo-gradient__100 {
		stop-color: $secondary-color;
	}
}

i.fa.fa-nodes {
	width: 1em;
	height: 1em;
	svg .logo-icon-gradient__0 {
		stop-color: $white;
	}
	svg .logo-icon-gradient__100 {
		stop-color: $white;
	}
}
a.list-group-item:hover,
li.list-group-item:hover {
	.fa.fa-nodes {
		svg .logo-icon-gradient__0 {
			stop-color: $primary-color;
		}
		svg .logo-icon-gradient__100 {
			stop-color: $primary-color;
		}
	}
}