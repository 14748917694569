@include block-layout-html-classes($size: small, $include-spacing: true);

@media (min-width: $screen-sm-min) {
	@include block-layout-html-classes($size: medium, $include-spacing: true);
}

@media (min-width: $screen-md-min) {
	@include block-layout-html-classes($size: large, $include-spacing: true);
}

@media (min-width: $screen-lg-min) {
	@include block-layout-html-classes($size: x-large, $include-spacing: true);
}