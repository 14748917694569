.file-picker {
	width: 100%;
}
.file-picker:not(.file-picker--no-preview) .file-picker__input-group {
	@include whitespace($side: left, $type: margin);
}
.file-picker__inner {
	display: flex;
	align-items: center;
}
.file-picker__zone:not([style]) {
	padding-bottom: 25%;
}
.file-picker__zone {
	position: relative;
	flex: 1 0 auto;
	width: 25%;

	border-radius: $border-radius-base;
	border: 2px dotted $gray;
	overflow: hidden;
	&.highlight {
		border-color: $primary-color;
	}

	.file-picker__icon {
		display: none;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		font-size: 32px;
		text-align: center;
		background-color: $white;
	}
	.file-picker__drop-text {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);

		font-size: 10px;
		text-align: center;
	}
	.file-picker__clear.btn {
		display: none;
		position: absolute;
		top: 5px;
		right: 5px;
		color: $white;
		z-index: 40;

		background-color: rgba(0, 0, 0, 0.5);
	}
}
.file-picker__preview {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 30;
	background-color: $white;
	&[src=""] {
		display: none;
	}
}
.file-picker__icon {
	position: absolute;
	z-index: 20;
}
.file-picker__input-group {
	position: relative;

	.input-group-btn {
		line-height: 20px;

		.btn {
			border-color: $input-border;
		}
	}

	input[type="file"] {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		opacity: 0;
		cursor: inherit;
		display: block;
		z-index: 10;
		&:hover {
			cursor: pointer;
			+ .input-group .input-group-btn .btn {
				border-color: $input-border;
				background-color: darken($btn-default-bg, 10%);
			}
		}
	}
	input[readonly] {
		margin-left: -1px;
		background-color: white !important;
		cursor: default;
		&:focus {
			border-color: $input-border;
		}
	}
}

.file-picker.file-picker--vertical {

	&:not(.file-picker--no-preview) .file-picker__inner {
		flex-wrap: wrap;
	}

	.file-picker__zone {
		@include whitespace($side: bottom, $type: margin);
	}

	.file-picker__input-group {
		width: 100%;
		margin-left: 0;
	}

}

.file-picker.file-picker--reverse {
	.file-picker__inner {
		flex-direction: row-reverse;
	}
}
.file-picker.file-picker--vertical.file-picker--reverse {
	.file-picker__inner {
		flex-direction: column-reverse;
	}
}
.file-picker.file-picker--reverse:not(.file-picker--no-preview) {
	.file-picker__input-group {
		margin: 0;
		@include whitespace($side: right, $type: margin);
	}
}
.file-picker.file-picker--vertical.file-picker--reverse:not(.file-picker--no-preview) {
	.file-picker__zone {
		margin: 0;
		@include whitespace($side: top, $type: margin);
	}
	.file-picker__input-group {
		margin: 0;
	}
}
