/*!
 * Bootstrap Colorpicker
 * http://mjolnic.github.io/bootstrap-colorpicker/
 *
 * Originally written by (c) 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0.txt
 *
 */

.colorpicker-saturation {
	float: left;
	width: $color-picker-default-size;
	height: $color-picker-default-size;
	cursor: crosshair;
	background-image: url("../img/bootstrap-colorpicker/saturation.png");
}

.colorpicker-saturation i {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 5px;
	height: 5px;
	margin: -4px 0 0 -4px;
	border: 1px solid #000;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.colorpicker-saturation i b {
	display: block;
	width: 5px;
	height: 5px;
	border: 1px solid #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.colorpicker-hue,
.colorpicker-alpha {
	float: left;
	width: $color-picker-slider-default-size;
	height: $color-picker-default-size;
	margin-bottom: 4px;
	margin-left: 4px;
	cursor: row-resize;
}

.colorpicker-hue i,
.colorpicker-alpha i {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 1px;
	margin-top: -1px;
	background: #000;
	border-top: 1px solid #fff;
}

.colorpicker-hue {
	background-image: url("../img/bootstrap-colorpicker/hue.png");
}

.colorpicker-alpha {
	display: none;
	background-image: url("../img/bootstrap-colorpicker/alpha.png");
}

.colorpicker-saturation,
.colorpicker-hue,
.colorpicker-alpha {
	background-size: contain;
}

.dropdown-menu.colorpicker {
	top: 0;
	left: 0;
	z-index: 2500;
	min-width: #{$color-picker-default-size + 30px};
	padding: 4px;
	margin-top: 1px;
	border-radius: $border-radius-base;
	*zoom: 1;
	background-color: $dropdown-bg;
	border: 1px solid $dropdown-fallback-border; // IE8 fallback
	border: 1px solid $dropdown-border;
	overflow: visible;
}

.color-picker__color {
	border: 1px solid $dropdown-border;
	border-radius: $border-radius-base;
}

.dropdown-menu.colorpicker:before,
.dropdown-menu.colorpicker:after {
	display: table;
	line-height: 0;
	content: "";
}

.dropdown-menu.colorpicker:after {
	clear: both;
}

.dropdown-menu.colorpicker:before {
	position: absolute;
	top: -7px;
	left: 6px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.dropdown-menu.colorpicker:after {
	position: absolute;
	top: -6px;
	left: 7px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	border-left: 6px solid transparent;
	content: '';
}

.dropdown-menu.colorpicker div {
	position: relative;
}

.dropdown-menu.colorpicker.colorpicker-with-alpha {
	min-width: 140px;
}

.dropdown-menu.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
	display: block;
}

.colorpicker-color {
	height: 10px;
	margin-top: 5px;
	clear: both;
	background-image: url("../img/bootstrap-colorpicker/alpha.png");
	background-position: 0 100%;
}

.colorpicker-color div {
	height: 10px;
}

.colorpicker-selectors {
	display: none;
	height: 10px;
	margin-top: 5px;
	clear: both;
}

.colorpicker-selectors i {
	float: left;
	width: 10px;
	height: 10px;
	cursor: pointer;
}

.colorpicker-selectors i + i {
	margin-left: 3px;
}

.colorpicker-element .input-group-addon i,
.colorpicker-element .add-on i {
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: text-top;
	cursor: pointer;
}

.dropdown-menu.colorpicker.colorpicker-inline {
	position: relative;
	z-index: auto;
	display: inline-block;
	float: none;
}

.dropdown-menu.colorpicker.colorpicker-horizontal {
	width: 110px;
	height: auto;
	min-width: 110px;
}

.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-saturation {
	margin-bottom: 4px;
}

.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-color {
	width: $color-picker-default-size;
}

.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-hue,
.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-alpha {
	float: left;
	width: $color-picker-default-size;
	height: $color-picker-slider-default-size;
	margin-bottom: 4px;
	margin-left: 0;
	cursor: col-resize;
}

.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-hue i,
.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-alpha i {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 1px;
	height: $color-picker-slider-default-size;
	margin-top: 0;
	background: #ffffff;
	border: none;
}

.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-hue {
	background-image: url("../img/bootstrap-colorpicker/hue-horizontal.png");
}

.dropdown-menu.colorpicker.colorpicker-horizontal .colorpicker-alpha {
	background-image: url("../img/bootstrap-colorpicker/alpha-horizontal.png");
}

.dropdown-menu.colorpicker.colorpicker-hidden {
	display: none;
}

.dropdown-menu.colorpicker.colorpicker-visible {
	display: block;
}

.dropdown-menu.colorpicker-inline.colorpicker-visible {
	display: inline-block;
}

.dropdown-menu.colorpicker-right:before {
	right: 6px;
	left: auto;
}

.dropdown-menu.colorpicker-right:after {
	right: 7px;
	left: auto;
}

.colorpicker.color-picker--large .colorpicker-saturation {
	width: $color-picker-large-size;
	height: $color-picker-large-size;
}
.colorpicker.color-picker--large .colorpicker-hue,
.colorpicker.color-picker--large .colorpicker-alpha {
	width: $color-picker-slider-large-size;
	height: $color-picker-large-size;
}
.colorpicker.color-picker--large .colorpicker-color,
.colorpicker.color-picker--large .colorpicker-color div {
	height: $color-picker-slider-large-size;
}