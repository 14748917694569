.list-group {
	margin-bottom: 0;
}
nav.list-group ul {
	padding: 0;
	margin: 0;
}

.list-group:not(.list-group-radius) .list-group-item {
	&:first-child,
	&:last-child {
		border-radius: 0;
	}
}

.list-group:not(.list-group-bordered) .list-group-item {
	border-top: none;
	border-bottom: none;
	border-right: none;
}

.list-group-bordered .list-group-item {
	border-left: 1px solid $list-group-border;
}
.list-group-bordered .list-group-heading {
	border: 1px solid $list-group-border;
	border-bottom: none;
}