.alert {
	border-radius: 0;
}

.page-header__alerts {
	.alert {
		@include whitespace($side: left);
		border: 0;
		margin-bottom: 0;
	}
}

.page-toasts {
	position: fixed;
	top: $toast-affix-top;
	right: $toast-affix-right;
	width: $toast-max-width;
	z-index: 900;
}

.page-toasts {
	.alert {
		@include whitespace();

		border-radius: 0;
		opacity: 1;

		transition: opacity 0.2s, transform 0.3s;

		&.to-be-animated-in {
			opacity: 0;
			transform: translateX(100%);
		}
		&.to-be-animated-out {
			opacity: 0;
			transform: translateX(-100%);
		}
	}
	.alert:last-of-type {
		@include whitespace($side: bottom, $type: margin);
	}
	.alert-dismissible .close {
		right: 0;
	}
}