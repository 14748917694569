.dropdown.dropdown-applications {
	@include font-color($secondary-color-light);

	.dropdown-menu {
		max-width: $core-sidebar-width;
	}

	.dropdown-content {
		padding: 0;
	}

	.application {
		@include layout($wrap: false);
		@include whitespace($side: horizontal);
		@include whitespace($side: bottom, $size: sm);
	}

	.application__icon {
		@include flex(1);
		min-width: 50px;
		max-width: 50px;
		border-radius: $border-radius-base;
		overflow: hidden;
	}
	.application__info {
		@include layout($direction: vertical, $wrap: false);
		@include flex(2);
		@include whitespace($type: margin, $side: left);
		justify-content: center;
	}

	.application__info-name {
		overflow: hidden;
		text-overflow: ellipsis;
		.indicator {
			float: right;
			padding-top: $whitespace-root / 2;
		}
	}

	.application-list,
	.application-list-title {
		@include background-color($secondary-color-darker);
	}

	.application-actions {
		@include clearfix;
		@include whitespace($side: horizontal);
		.application__dashboard {
			padding-left: 0;
			float: left;
		}
		.application__settings {
			padding-right: 0;
			float: right;
		}
	}

	.application-list-title {
		@include whitespace($side: horizontal);
		margin-bottom: 0;
	}

	.application-list {
		@include whitespace($side: vertical, $size: xs);
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li a {
			@include whitespace($side: horizontal);
			@include whitespace($side: vertical, $size: xs);
			display: block;
			&:hover, &.hover,
			&:focus, &.focus,
			&:active, &.active {
				text-decoration: none;
			}
		}
		.indicator {
			float: right;
			padding-top: 5px;
		}
	}

	.application-list__more {
		text-align: center;
		.fa {
			@include whitespace($type: margin, $side: right, $size: sm);
		}
	}

	.application__settings {
		@include whitespace($side: right);
		float: right;
	}

}