@mixin aspect-ratio-block-layout-charts($size) {
	@for $i from 1 through $block-layout-elements {
		.#{$size}-block-layout-#{($i)} {
			> .chart-responsive {
				padding-bottom: 67.5%;
			}
		}
	}
}

@mixin aspect-ratio-grid-charts($size) {
	@for $i from 1 through $grid-columns {
		.col-#{$size}-#{($i)} {
			> .chart-responsive {
				padding-bottom: 67.5%;
			}
		}
	}
}