.action-wrapper {
	position: relative;
}
.action-wrap-action {
	@include whitespace($size: sm);
	position: absolute;
	top: 0;
	border: none;

	&.action-wrap-left {
		left: 0;
	}
	&.action-wrap-right {
		right: 0;
	}

	&.action-wrap-sm {
		@include whitespace($side: vertical, $size: xs);
	}
}
.action-wrap-item {
	&.action-wrap-left {
		@include whitespace($side: left, $size: xl);
	}
	&.action-wrap-right {
		@include whitespace($side: right, $size: xl);
	}
}