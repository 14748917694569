.chart-responsive {
	//position: relative;
	//width: 100%;
	//height: 100%;
	canvas {
		//position: absolute;
		width: 100% !important;
		height: auto !important;
	}
}

@include aspect-ratio-block-layout-charts($size: small);
@include aspect-ratio-grid-charts($size: xs);


@media (min-width: $screen-sm-min) {
	@include aspect-ratio-block-layout-charts($size: medium);
	@include aspect-ratio-grid-charts($size: sm);
}

@media (min-width: $screen-md-min) {
	@include aspect-ratio-block-layout-charts($size: large);
	@include aspect-ratio-grid-charts($size: md);
}

@media (min-width: $screen-lg-min) {
	@include aspect-ratio-block-layout-charts($size: x-large);
	@include aspect-ratio-grid-charts($size: lg);
}