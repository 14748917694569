.form-control.transparent {
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;
}

.form-control {
	@include placeholder($color: $gray);
	box-shadow: none;
	&:focus,
	&.focus {
		box-shadow: none;
	}

}

.form-control[readonly="readonly"] {
	cursor: not-allowed;

	> option {
		display: none;
	}
}