.app-tile-aspect-ratio {
	position: relative;
	&::before {
		content: '';
		display: block;
	}

	.app-tile {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.app-tile-aspect-ratio--1-1 {
	&::before {
		padding-top: 100%;
	}
}
.app-tile-aspect-ratio--2-1 {
	&::before {
		padding-top: 50%;
	}
}
.app-tile-aspect-ratio--1-2 {
	&::before {
		padding-top: 200%;
	}
}
.app-tile-aspect-ratio--4-3 {
	&::before {
		padding-top: 75%;
	}
}
.app-tile-aspect-ratio--16-9 {
	&::before {
		padding-top: 56.25%;
	}
}