html, body {
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow: hidden;
}
body.local,
body.staging {
	padding-bottom: 40px;
	.core-layout__content {
		padding-bottom: 20px;
	}
}

.core-layout {
	width: 100%;
	height: 100%;
	max-height: 100%;
}

.core-layout__page {
	display: flex;
	flex: 1 1 0%;
	height: 100%;
	max-height: 100%;
}

.core-layout__topbar {
	@include background-color($secondary-color);
	height: $core-topbar-height;

	.top-bar {
		display: flex;
		height: $core-topbar-height;
	}
	.top-bar__left {
		display: flex;
		flex-basis: 100%;
		align-items: center;
		@media (min-width: $screen-sm) {
			justify-content: space-between;
			min-width: $core-sidebar-width;
			max-width: $core-sidebar-width;
		}
	}
	.top-bar__right {
		display: flex;
		flex-basis: 0;
		align-items: center;
		justify-content: space-between;
		.top-bar__actions-left,
		.top-bar__actions-right {
			align-items: center;
		}
		.top-bar__actions-left {
			display: none;
		}
		.top-bar__actions-right {
			display: none;
		}
		@media (min-width: $screen-sm) {
			width: 100%;
			flex-basis: auto;
			.top-bar__actions-right,
			.top-bar__actions-left {
				display: flex;
			}
		}
	}
}

.core-layout__sidebar-wrapper {
	position: fixed;
	flex: 1 1 0%;
	width: 100%;
	//height: 100%;
	max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;

	opacity: 0;
	display: none;

	background-color: rgba(0, 0, 0, 0.5);

	@media (min-width: $screen-sm) {
		display: block;
		position: static;
		width: $core-sidebar-width;
		min-width: $core-sidebar-width;
		max-width: $core-sidebar-width;

		opacity: 1;

		@include background-color($secondary-color-dark);
	}
}
.core-layout__sidebar {
	display: flex;
	flex-direction: column;

	position: fixed;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;
	width: $core-sidebar-width;
	min-width: $core-sidebar-width;
	max-width: $core-sidebar-width;

	overflow-y: auto;

	transform: translate3d(-100%, 0, 0);

	@media (min-width: $screen-sm) {
		height: 100%;
		max-height: 100%;
		position: static;
		transform: translate3d(0,0,0);
	}

	.sidebar__navigation {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		@media (min-width: $screen-sm) {

		}

		.sidebar__navigation-top:not(:empty) {
			@include whitespace($side: vertical, $type: margin, $size: sm);

		}
		.sidebar__navigation-middle {
			@include whitespace($side: vertical, $type: margin, $size: sm);
			flex: 1 0 auto;
			-webkit-overflow-scrolling: touch;
			@media (min-width: $screen-sm) {

				overflow-y: auto;
			}
		}
		.sidebar__navigation-bottom:not(:empty) {
			@include whitespace($side: vertical, $type: margin, $size: sm);

		}
	}
}
.core-layout__sidebar-mobile-navigation {
	width: $core-sidebar-width;
}

.core-layout__content {
	//height: 100%;
	max-height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;


	.page-content {

	}
}

.core-layout__sidebar-mobile-navigation,
.core__left-sidebar-toggle {
	@media (min-width: $screen-sm) {
		display: none;
	}
}

.core-layout__sidebar-mobile-navigation-toolbar {
	@include whitespace($side: vertical);
	position: relative;
	p {
		@include font-color($secondary-color-light);
		text-align: center;
		margin: 0;
	}
	.core__left-sidebar-toggle {
		position: absolute;
		top: 50%;
		right: 5px;
		transform: translateY(-50%);
	}
}

