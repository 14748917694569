.collapse-toggle {
	@include clearfix;
	display: block;
}
.collapse-toggle img {
	@include whitespace($side: right, $type: margin);
	width: 30px;
	float: left;
}
.collapse-toggle span,
.collapse-toggle img {
	float: left;
}
.collapse-toggle span {
	line-height: 30px;
}

.sidebar__collapse .collapse-toggle {
	width: 100%;
	text-align: left;
}
.sidebar__collapse .collapse {
	width: 100%;
}