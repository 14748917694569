$default-float: left !default;

//
// We use more breakpoints than Bootstrap
//
$screen-xl: 1440px !default;
$screen-xl-min: $screen-xl !default;
$screen-xxl: 1920px !default;
$screen-xxl-min: $screen-xxl !default;

//
// Block-Layout
//
$block-layout-elements: 12 !default;
$block-layout-spacing: 20px !default;

//
// Logo Size
//
$logo-size: 80px !default;
$logo-size-small: 40px !default;
$logo-size-large: 120px !default;
$logo-size-xlarge: 160px !default;

//
//  Primary Colors
//
$primary-color: #37EF81 !default;
$primary-color-dark: darken($primary-color, 15);
$primary-color-darker: darken($primary-color, 30);
$primary-color-light: lighten($primary-color, 15);
$primary-color-lighter: lighten($primary-color, 30);

$primary-color-darker-025: transparentize($primary-color-darker, 0.75);
$primary-color-darker-050: transparentize($primary-color-darker, 0.5);
$primary-color-dark-025: transparentize($primary-color-dark, 0.75);
$primary-color-dark-050: transparentize($primary-color-dark, 0.5);
$primary-color-025: transparentize($primary-color, 0.75);
$primary-color-050: transparentize($primary-color, 0.5);
$primary-color-light-025: transparentize($primary-color-light, 0.75);
$primary-color-light-050: transparentize($primary-color-light, 0.5);
$primary-color-lighter-025: transparentize($primary-color-lighter, 0.75);
$primary-color-lighter-050: transparentize($primary-color-lighter, 0.5);

//
// Secondary Colors
//
$secondary-color: #2B4454 !default;
$secondary-color-dark: #12222F !default;
$secondary-color-darker: #121016 !default;
$secondary-color-light: #638796 !default;
$secondary-color-lighter: #E8EEF0 !default;

$secondary-color-darker-025: transparentize($secondary-color-darker, 0.75);
$secondary-color-darker-050: transparentize($secondary-color-darker, 0.5);
$secondary-color-dark-025: transparentize($secondary-color-dark, 0.75);
$secondary-color-dark-050: transparentize($secondary-color-dark, 0.5);
$secondary-color-025: transparentize($secondary-color, 0.75);
$secondary-color-050: transparentize($secondary-color, 0.5);
$secondary-color-light-025: transparentize($secondary-color-light, 0.75);
$secondary-color-light-050: transparentize($secondary-color-light, 0.5);
$secondary-color-lighter-025: transparentize($secondary-color-lighter, 0.75);
$secondary-color-lighter-050: transparentize($secondary-color-lighter, 0.5);

//
// Grays
//
$gray-darker: $secondary-color-light !default;
$gray-dark: lighten($gray-darker, 16) !default;
$gray: lighten($gray-darker, 32) !default;
$gray-light: $secondary-color-lighter !default;
$gray-lighter: lighten($gray-light, 4) !default;

$gray-darker-025: transparentize($gray-darker, 0.75);
$gray-darker-050: transparentize($gray-darker, 0.5);
$gray-dark-025: transparentize($gray-dark, 0.75);
$gray-dark-050: transparentize($gray-dark, 0.5);
$gray-025: transparentize($gray, 0.75);
$gray-050: transparentize($gray, 0.5);
$gray-light-025: transparentize($gray-light, 0.75);
$gray-light-050: transparentize($gray-light, 0.5);
$gray-lighter-025: transparentize($gray-lighter, 0.75);
$gray-lighter-050: transparentize($gray-lighter, 0.5);

//
// Contextual Colors
//

// Info
$info-color: #639FDB !default;
$info-color-dark: darken($info-color, 15) !default;
$info-color-darker: darken($info-color, 30) !default;
$info-color-light: lighten($info-color, 15) !default;
$info-color-lighter: lighten($info-color, 30) !default;

$info-color-darker-025: transparentize($info-color-darker, 0.75);
$info-color-darker-050: transparentize($info-color-darker, 0.5);
$info-color-dark-025: transparentize($info-color-dark, 0.75);
$info-color-dark-050: transparentize($info-color-dark, 0.5);
$info-color-025: transparentize($info-color, 0.75);
$info-color-050: transparentize($info-color, 0.5);
$info-color-light-025: transparentize($info-color-light, 0.75);
$info-color-light-050: transparentize($info-color-light, 0.5);
$info-color-lighter-025: transparentize($info-color-lighter, 0.75);
$info-color-lighter-050: transparentize($info-color-lighter, 0.5);

// Success
$success-color: #37DDAD !default;
$success-color-dark: darken($success-color, 15) !default;
$success-color-darker: darken($success-color, 30) !default;
$success-color-light: lighten($success-color, 15) !default;
$success-color-lighter: lighten($success-color, 30) !default;

$success-color-darker-025: transparentize($success-color-darker, 0.75);
$success-color-darker-050: transparentize($success-color-darker, 0.5);
$success-color-dark-025: transparentize($success-color-dark, 0.75);
$success-color-dark-050: transparentize($success-color-dark, 0.5);
$success-color-025: transparentize($success-color, 0.75);
$success-color-050: transparentize($success-color, 0.5);
$success-color-light-025: transparentize($success-color-light, 0.75);
$success-color-light-050: transparentize($success-color-light, 0.5);
$success-color-lighter-025: transparentize($success-color-lighter, 0.75);
$success-color-lighter-050: transparentize($success-color-lighter, 0.5);

// Warning
$warning-color: #E3D968 !default;
$warning-color-dark: darken($warning-color, 15) !default;
$warning-color-darker: darken($warning-color, 30) !default;
$warning-color-light: lighten($warning-color, 15) !default;
$warning-color-lighter: lighten($warning-color, 30) !default;

$warning-color-darker-025: transparentize($warning-color-darker, 0.75);
$warning-color-darker-050: transparentize($warning-color-darker, 0.5);
$warning-color-dark-025: transparentize($warning-color-dark, 0.75);
$warning-color-dark-050: transparentize($warning-color-dark, 0.5);
$warning-color-025: transparentize($warning-color, 0.75);
$warning-color-050: transparentize($warning-color, 0.5);
$warning-color-light-025: transparentize($warning-color-light, 0.75);
$warning-color-light-050: transparentize($warning-color-light, 0.5);
$warning-color-lighter-025: transparentize($warning-color-lighter, 0.75);
$warning-color-lighter-050: transparentize($warning-color-lighter, 0.5);

// Danger
$danger-color: #CF664E !default;
$danger-color-dark: darken($danger-color, 15) !default;
$danger-color-darker: darken($danger-color, 30) !default;
$danger-color-light: lighten($danger-color, 15) !default;
$danger-color-lighter: lighten($danger-color, 30) !default;

$danger-color-darker-025: transparentize($danger-color-darker, 0.75);
$danger-color-darker-050: transparentize($danger-color-darker, 0.5);
$danger-color-dark-025: transparentize($danger-color-dark, 0.75);
$danger-color-dark-050: transparentize($danger-color-dark, 0.5);
$danger-color-025: transparentize($danger-color, 0.75);
$danger-color-050: transparentize($danger-color, 0.5);
$danger-color-light-025: transparentize($danger-color-light, 0.75);
$danger-color-light-050: transparentize($danger-color-light, 0.5);
$danger-color-lighter-025: transparentize($danger-color-lighter, 0.75);
$danger-color-lighter-050: transparentize($danger-color-lighter, 0.5);

//
// Not so much colors...
//
$white: #fff !default;
$black: #000 !default;

// Shading
$text-shade-light-primary-opacity: 1 !default;
$text-shade-light-secondary-opacity: 0.7 !default;
$text-shade-light-hint-opacity: 0.3 !default;
$text-shade-dark-primary-opacity: 0.87 !default;
$text-shade-dark-secondary-opacity: 0.54 !default;
$text-shade-dark-hint-opacity: 0.38 !default;

//
// Shadows
//
$shadow-distance-1: 0 2px 10px 0 rgba(0, 0, 0, 0.16) !default;
$shadow-distance-2: 0 6px 20px 0 rgba(0, 0, 0, 0.19) !default;
$shadow-distance-3: 0 17px 50px 0 rgba(0, 0, 0, 0.19) !default;
$shadow-distance-4: 0 25px 55px 0 rgba(0, 0, 0, 0.21) !default;
$shadow-distance-5: 0 40px 77px 0 rgba(0, 0, 0, 0.22) !default;

//
// Whitespace
//
$whitespace-root: 5px !default;

$whitespace-small-modifier: 2 !default;
$whitespace-base-modifier: 4 !default;
$whitespace-large-modifier: 6 !default;
$whitespace-xlarge-modifier: 8 !default;

//
// Typography
//
// Let's copy from BS, not the best solution...
$font-size-base: 14px;
$line-height-base: 1.428571429;

$font-size-giga: floor(($font-size-base * 2.6)) * 2; // ~72px
$font-size-mega: floor(($font-size-base * 2.6)) * 1.33; // ~48px

$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-semi-light: 400;
$font-weight-light: 300;

//
// Animation
//
$animation-timing-short: 200ms;
$animation-timing-base: 400ms;
$animation-timing-long: 600ms;

$animation-easing-relaxed: ease;
$animation-easing-beziered: cubic-bezier(.7,0,.3,1);

$animation-expand-magic-number: 500px;

//
// Core Layout sizes
//
$core-sidebar-width: 250px !default;
$core-sidebar-logo-height: 100px !default;
$core-topbar-height: 60px !default;


//
// Page Header
//
$page-header-height: 130px !default;

// Jumbotrons Extended
$jumbotron-inverse-color:			$white !default;
$jumbotron-inverse-bg:				$secondary-color-dark !default;
$jumbotron-inverse-heading-color:	$white !default;
$jumbotron-inverse-link-color:		$secondary-color-light !default;

//
// Components
//
$border-radius-base: 4px !default;

// Bootstrap datetimepicker
$bs-datetimepicker-width: 20em;
$bs-datetimepicker-timepicker-font-size: 1.2em;
$bs-datetimepicker-active-bg: $primary-color;
$bs-datetimepicker-active-color: $white;
$bs-datetimepicker-border-radius: $border-radius-base;
$bs-datetimepicker-btn-hover-bg: $gray-lighter;
$bs-datetimepicker-disabled-color: $gray-light;
$bs-datetimepicker-alternate-color: $gray-light;
$bs-datetimepicker-secondary-border-color: #ccc;
$bs-datetimepicker-secondary-border-color-rgba: rgba(0, 0, 0, 0.2);
$bs-datetimepicker-primary-border-color: white;
$bs-datetimepicker-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

//
// Alerts and Toasts
//
$toast-max-width: 400px !default;
$toast-affix-top: $core-topbar-height + 20px !default;
$toast-affix-right: 40px !default;

//
// Color-picker
//
$color-picker-default-size: 100px;
$color-picker-large-size: 200px;
$color-picker-slider-default-size: 15px;
$color-picker-slider-large-size: 30px;

//
// Spinkit (spinners)
//
$spinkit-spinner-margin: 0 auto;
$spinkit-size: 40px;
$spinkit-spinner-color: $primary-color;