.label {
	font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
	.label {
		padding-top: 0;
		padding-bottom: 0;
		line-height: 20px;
	}
}