.drop-element, .drop-element:after, .drop-element:before, .drop-element *, .drop-element *:after, .drop-element *:before {
	box-sizing: border-box; }

.drop-element {
	position: absolute;
	display: none; }
.drop-element.drop-open {
	display: block; }

.drop-element.drop-theme-basic {
	max-width: 100%;
	max-height: 100%; }
.drop-element.drop-theme-basic .drop-content {
	border-radius: 5px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	font-family: inherit;
	background: #eee;
	color: #444;
	padding: 1em;
	font-size: 1.1em;
	line-height: 1.5em; }

.drop {
	z-index: 1060;
}

.drop .dropdown-menu {
	position: static;
	display: block;
	top: auto;
	left: auto;
}

.drop-element {
	opacity: 0;
}
.drop-element.drop-open.transitionend {
	display: block;
}
.drop-element.drop-after-open {
	opacity: 1;
	transition: opacity $animation-timing-short;
}