.app-install {
	opacity: 0;
	transition: opacity .2s;
	&.initialized {
		opacity: 1;
	}
}

.app-install__header {
	.jumbotron {
		@include clearfix;
		padding: 0;
	}

	.logo {
		@include whitespace($type: margin, $side: top, $size: xl);
		@include whitespace($type: margin, $side: bottom, $size: lg);
		margin-right: auto;
		margin-left: auto;
	}

	h1 {
		margin-top: 0;
		@include whitespace($type: margin, $side: bottom, $size: lg);
		@include whitespace($type: padding, $side: horizontal, $size: default);
		font-size: $font-size-h3;
	}

	.panel {
		transform: translate3d(0,0,0);
		margin-bottom: 0;
	}
	.panel-body {
		@include whitespace($type: padding, $side: vertical, $size: default);
	}

	.slick-slider-indicators {
		@include clearfix;
		@include whitespace($type: padding, $side: bottom, $size: default);
		margin: 0 auto;
	}

	.slick-dots {
		margin: 0;
		padding: 0;
		text-align: center;
		li {
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 4px;
			padding: 2px;
			border: 1px solid transparent;
			border-radius: 50%;
			transition: all .2s;
			&:hover {
				cursor: pointer;
				border-color: $secondary-color-light;
			}
		}
		li:last-of-type {
			margin-right: 0;
		}
		li span {
			display: block;
			width: 100%;
			height: 100%;
			background-color: $white;
			border-radius: 50%;
			transition: all .2s;
		}

		li.slick-active {
			border-color: $secondary-color-light;
		}
		li.slick-active span {
			background-color: $primary-color;
		}
	}
}
.slick-slider-child {
	//width: 320px;
	width: 100%;
	margin: 0 auto;
	@media screen and (min-width: $screen-xs-min) {
		width: 320px * 2;
	}
	.slice-slide {
		&:focus {
			outline: none;
		}
	}
	.slick-slide p {
		max-width: 100%;
		padding: 0 20px;
	}
}
.app-install__carousel {
	.slick-slider {
		width: 100%;
		margin: 0 auto;
		padding-bottom: 20px;
		@media screen and (min-width: $screen-xs-min) {
			width: 320px * 2;
		}
	}
	.slick-track:hover {
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
	}
	.slick-track:active {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}
	.slick-slide {

		padding: 20px 10px 0 10px;

		&:focus {
			outline: none;
		}
	}
	.slick-slide__inner {
		position: relative;
		text-align: center;

		img {
			@media screen and (min-width: $screen-xs-min) {
				max-height: 480px;
				max-height: 50vh;
			}
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			opacity: 1;
			transition: opacity .2s;
		}
		@media screen and (min-width: $screen-sm-min) {
			&:after {
				opacity: 0;
			}
		}
	}
	.slick-slide__inner img {
		margin: 0 auto;
	}
	.slick-center .slick-slide__inner:after {
		opacity: 0;
	}
}