.core-layout__topbar {

	.dropdown-toggle {
		@include font-color($secondary-color-light);
	}

	.open > .btn.btn-transparent.dropdown-toggle {
		&:hover, &.hover {

		}
		&:focus, &.focus,
		&:active, &.active {
			@include font-color($secondary-color-light);
		}
	}

	.top-bar__actions-left,
	.top-bar__actions-right {
		flex-basis: 50%;
	}
	.top-bar__actions-left {
		justify-content: center;
		@media (min-width: $screen-sm) {
			justify-content: flex-start;
		}
	}
	.top-bar__actions-right {
		justify-content: flex-end;
	}
}
.top-bar__left {
	@include whitespace($side: horizontal);
	@include background-color($secondary-color-dark);
	@media (min-width: $screen-sm) {
	}

	.core__left-sidebar-toggle {
		position: absolute;
		top: $whitespace-root * $whitespace-small-modifier;
		left: $whitespace-root * $whitespace-small-modifier;
		@media (min-width: $screen-sm) {
			position: static;
		}
	}

	.application-brand {
		@include clearfix;
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		@media (min-width: $screen-sm) {
			margin-left: 0;
			margin-right: 0;
		}
		> * {
			display: block;
			float: left;
		}
		.application-title {
			@include whitespace($side: left, $type: margin, $size: sm);
			@include font-color($gray-lighter);
			font-size: $font-size-large;
		}
	}
}
.top-bar__right {
	.dropdown-toggle:hover, .dropdown-toggle.hover,
	.dropdown-toggle:focus, .dropdown-toggle.focus,
	.dropdown-toggle:active, .dropdown-toggle.active,
	.open > .btn.btn-transparent.dropdown-toggle {
		@include font-color(darken($secondary-color-light, 5%));
	}
}

.top-bar a.logo {
	display: block;
	width: $logo-size-small;
	height: $logo-size-small;
}

.top-bar .dropdown {

	[data-toggle="dropdown"] {
		height: 60px;
		border: none;
	}

}