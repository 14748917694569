.table {

	> tbody {
		> tr {
			> th.table-actions,
			> td.table-actions {
				.btn {
					@include whitespace($side: bottom, $type: margin, $size: xs);
					@include whitespace($side: right, $type: margin, $size: xs);
					float: left;
					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}
}
.table-actions {

}