.app-tile {
	position: relative;

	@include background-color($white);
	@include shadow($distance: 1);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	border-radius: $border-radius-base;
	transition: box-shadow .2s;

	overflow: hidden;

	&:hover, &:focus, &:active,
	&.hover, &.focus, &.active {
		text-decoration: none;
		@include shadow($distance: 2);
	}

}

.app-tile a {
	&:hover,
	&.hover {
		text-decoration: none;
	}
}

.app-tile--icon,
.app-tile--icon a,
.app-tile--cover,
.app-tile--link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.app-tile__cover {
	width: 100%;
}