// Resets (why act all wierd spinkit :-/ )
.spinner .sk-fading-circle,
.spinner .sk-fading-circle .sk-circle {
	margin-top: 0;
	margin-bottom: 0;
}

.spinner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: $spinkit-size;

	margin: 0 auto;
}

.spinner.spinner-small {
	.sk-rotating-plane {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
	.sk-double-bounce {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
	.sk-wave {
		width: ($spinkit-size / 2) + 10;
		height: $spinkit-size / 2;
		.sk-rect {
			width: 3px;
		}
	}
	.sk-spinner-pulse {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
	.sk-chasing-dots {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
	.sk-three-bounce {
		width: $spinkit-size;
		.sk-child {
			width: $spinkit-size / 4;
			height: $spinkit-size / 4;
		}
	}
	.sk-circle {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
	.sk-cube-grid {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
	.sk-fading-circle {
		width: $spinkit-size / 2;
		height: $spinkit-size / 2;
	}
}

.spinner.spinner-secondary {
	.sk-rotating-plane {
		background-color: $secondary-color;
	}
	.sk-double-bounce {
		.sk-child {
			background-color: $secondary-color;
		}
	}
	.sk-wave {
		.sk-rect {
			background-color: $secondary-color;
		}
	}
	.sk-spinner-pulse {
		background-color: $secondary-color;
	}
	.sk-chasing-dots {
		.sk-child {
			background-color: $secondary-color;
		}
	}
	.sk-three-bounce {
		.sk-child {
			background-color: $secondary-color;
		}
	}
	.sk-circle {
		.sk-child:before {
			background-color: $secondary-color;
		}
	}
	.sk-cube-grid {
		.sg-cube {
			background-color: $secondary-color;
		}
	}
	.sk-fading-circle {
		.sk-circle:before {
			background-color: $secondary-color;
		}
	}
}

.spinner.spinner-white {
	.sk-rotating-plane {
		background-color: $white;
	}
	.sk-double-bounce {
		.sk-child {
			background-color: $white;
		}
	}
	.sk-wave {
		.sk-rect {
			background-color: $white;
		}
	}
	.sk-spinner-pulse {
		background-color: $white;
	}
	.sk-chasing-dots {
		.sk-child {
			background-color: $white;
		}
	}
	.sk-three-bounce {
		.sk-child {
			background-color: $white;
		}
	}
	.sk-circle {
		.sk-child:before {
			background-color: $white;
		}
	}
	.sk-cube-grid {
		.sg-cube {
			background-color: $white;
		}
	}
	.sk-fading-circle {
		.sk-circle:before {
			background-color: $white;
		}
	}
}

.spinner.spinner-black {
	.sk-rotating-plane {
		background-color: $black;
	}
	.sk-double-bounce {
		.sk-child {
			background-color: $black;
		}
	}
	.sk-wave {
		.sk-rect {
			background-color: $black;
		}
	}
	.sk-spinner-pulse {
		background-color: $black;
	}
	.sk-chasing-dots {
		.sk-child {
			background-color: $black;
		}
	}
	.sk-three-bounce {
		.sk-child {
			background-color: $black;
		}
	}
	.sk-circle {
		.sk-child:before {
			background-color: $black;
		}
	}
	.sk-cube-grid {
		.sg-cube {
			background-color: $black;
		}
	}
	.sk-fading-circle {
		.sk-circle:before {
			background-color: $black;
		}
	}
}