.app-tile {
	@include whitespace();
}
.app-tile--xs {
	@include whitespace($size: xs);
}
.app-tile--sm {
	@include whitespace($size: sm);
}
.app-tile--lg {
	@include whitespace($size: lg);
}
.app-tile--xl {
	@include whitespace($size: xl);
}
.app-tile--no-padding {
	padding: 0;
}

.app-tile--cover,
.app-tile--link {
	padding: 0;
	a {
		@include whitespace();
	}
}