.page-login {
	@include background-color($secondary-color-dark);
	align-items: center;
	justify-content: center;

	.panel {
		@include background-color(transparent);
		border-color: transparent;

		@media (min-width: $screen-sm-min) {
			min-width: 400px;
			max-width: 400px;
		}
	}

	.panel-heading {
		text-align: center;
		.logo {
			@include whitespace($type: margin, $side: bottom);
		}
		h3 {
			@include font-color($secondary-color-light);
		}
	}

	input {
		// TODO: UD HERFRA, SKAL LAVES MED CLASS
		@include background-color($secondary-color-lighter);
		@include font-color($secondary-color-dark);
	}

	label, a {
		@include font-color($secondary-color-light);
	}
}
/**
 * "Secret" page curl
 */
#alternative-login {
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0; right: 0;
	background:
	linear-gradient(
					225deg,
					transparent 40%,
					#f3f3f3 50%,
					#ccc 50%,
					#bbb 50%,
					#ddd 62%,
					#f3f3f3 80%,
					#fff 100%
	), #f3f3f3 url('../images/nodes.png') top right no-repeat;
	background-size: auto, 62px;
	box-shadow : 0 0 10px rgba(0, 0, 0, .5);
	transition: all .35s ease;
	z-index: 100;

	&:before, &:after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 12.5%;
		bottom: 5.8%;
		width: 70%;
		max-width: 100px;
		max-height: 80px;
		height: 55%;
		box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
		transform: skew(10deg) rotate(6deg);
	}

	&:after {
		left: auto;
		right: 5.8%;
		bottom: auto;
		top: 14.16%;
		transform: skew(-15deg) rotate(-84deg);
	}

	&:hover {
		width: 80px;
		height: 80px;

		&:before, &:after {
			box-shadow: 0 14px 20px rgba(0, 0, 0, .3);
		}
	}
}