.app-tile--primary {
	@include background-color($primary-color);
	@include font-color($primary-color-darker);
}
.app-tile--secondary {
	@include background-color($secondary-color);
	@include font-color($secondary-color-lighter);
}
.app-tile--info {
	@include background-color($info-color);
	@include font-color($info-color-lighter);
}
.app-tile--success {
	@include background-color($success-color);
	@include font-color($success-color-darker);
}
.app-tile--warning {
	@include background-color($warning-color);
	@include font-color($warning-color-darker);
}
.app-tile--danger {
	@include background-color($danger-color);
	@include font-color($danger-color-lighter);
}
.app-tile--gray-dark {
	@include background-color($gray-darker);
	@include font-color($gray-light);
}
.app-tile--gray-light {
	@include background-color($gray-light);
	@include font-color($gray-darker);
}